// App.js - WEB
import React, { Component, useEffect, useState } from 'react';
import axios from "axios";
import { baseURL } from '../../framework/src/config';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { Dialog, Box, Typography, Button, Grid } from '@material-ui/core';
import "react-toastify/dist/ReactToastify.css";
import { View } from 'react-native';
import addNotification from 'react-push-notification';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { Navigate, Route } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import AlcohalSubstance from '../../blocks/goalmanagement/src/AlcohalSubstance.web';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import SubstanceGoals from "../../blocks/customform/src/SubstanceGoals.web";
import WellSpring from "../../blocks/landingpage/src/WellSpring.web";
import Analytics from "../../blocks/analytics/src/Analytics";
import HamburgerMenu from '../../blocks/hamburgermenu/src/HamburgerMenu.web'
import Goals from '../../blocks/customform/src/Goals.web'
import OtherGoals from '../../blocks/goalmanagement/src/OtherGoals.web'
import SaferUserStrategies from '../../blocks/goalmanagement/src/SaferUserStrategies.web'
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories.web'
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import AssessmentTest from "../../blocks/assessmenttest/src/AssessmentTest";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Sorting from "../../blocks/sorting/src/Sorting";

import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import DashboardInformation from "../../blocks/dashboard/src/DashboardInformation.web";
import GoalsInformation from "../../blocks/dashboard/src/GoalsInformation.web"
import SaferuseStrategies from "../../blocks/dashboard/src/SaferuseStrategies.web"
import CustomForm from "../../blocks/customform/src/CustomForm";
import Assessment from "../../blocks/assessmenttest/src/Assessment.web"
import ContactUs from '../../blocks/email-account-login/src/ContactUs.web'
import { platform } from 'os';
import { set } from 'lodash';
import { background } from '../../blocks/goalmanagement/src/assets';


const RouteGenerator = () => {
  <Route path="*" element={<Navigate to="/EmailAccountLoginBlock" />} />
};
const routeMap = {
  Assessment: {
    component: Assessment,
    path: "/assessment"
  },
  SaferuseStrategies: {
    component: SaferuseStrategies,
    path: "/SaferuseStrategies"
  },
  GoalsInformation: {
    component: GoalsInformation,
    path: "/goalsinformation"
  },
  DashboardInformation: {
    component: DashboardInformation,
    path: "/dashboardinformation"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  HamburgerMenu: {
    component: HamburgerMenu,
    path: "/HamburgerMenu"
  },
  OtherGoals: {
    component: OtherGoals,
    path: '/OtherGoals'
  },
  //  SaferUserStrategies
  SaferUserStrategies: {
    component: SaferUserStrategies,
    path: '/SaferUserStrategies'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Goals: {
    component: Goals,
    path: "/Goals"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  WellSpring: {
    component: WellSpring,
    path: "/WellSpring"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  ContactUs: {
    component: ContactUs,
    path: "/ContactUs"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: "/AssessmentTest"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  CustomForm: {
    component: CustomForm,
    path: "/CustomForm"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },

  SubstanceGoals: {
    component: SubstanceGoals,
    path: "/SubstanceGoals"
  },
  Home: {
    component: LandingPage,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  AlcohalSubstance: {
    component: AlcohalSubstance,
    path: '/AlcohalSubstance',
  },
};

const firebaseConfig = {
  apiKey: "AIzaSyBpAvAtKUZNw_BikP3KFRbwPkSOl5XkU8I",
  authDomain: "wellspring-6c06f.firebaseapp.com",
  projectId: "wellspring-6c06f",
  storageBucket: "wellspring-6c06f.firebasestorage.app",
  messagingSenderId: "703714078388",
  appId: "1:703714078388:web:fa3409f47a542348bd31e1",
  measurementId: "G-1LMNC6ZVH0"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


export const generateToken = async (permission) => {
  // const permission = await Notification.requestPermission();
  console.log("Permission:", permission);
  if (permission === 'granted') {
    const firebaseToken = await getToken(messaging, {
      vapidKey:
        "BDuSPJ30CnK-cnFzr9sg8qO3Er24NOe7fXP-2Wg1OwQmk-cmus82g_TKM4SeNEzuTbY3Ps49zJ6JvdKyUN8bXZY"
    });

    console.log("Firebase token:", firebaseToken);
    localStorage.setItem("firebaseToken", firebaseToken);
    if (localStorage.getItem("token")) {
      try {
        const response = await axios.post(`${baseURL}/account_block/add_device_id`, {
          "device_id": firebaseToken
        },
          {
            headers: {
              "token": `${localStorage.getItem("token")}`
            }
          }
        );
      } catch (error) {
        console.error("Error sending token:", error);
      }
    }
  } else {
    console.log("permission", permission);
  }
}

function getPlatform() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    // generateToken()
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    // generateToken()
    return "iOS";
  }
  if (/Win/i.test(userAgent)) {
    // generateToken()
    return "Windows";
  }
  if (/Mac/i.test(userAgent)) {
    // generateToken()
    return "Mac";
  }
  if (/Linux/i.test(userAgent)) {
    // generateToken()
    return "Linux";
  }
  return "Web";
}

console.log("Get platform: ", getPlatform());

// generateToken();
onMessage(messaging, (payload) => {
  const buttonClick = () => {
    addNotification({
      title: payload.notification.title,
      message: payload.notification.body,
      theme: 'light',
      native: true,
      icon: payload.notification.image,
      backgroundTop: '#ffffff',
      backgroundBottom: '#f8f9fa',
      color: '#333',
      duration: 80000,
      customStyles: {
        icon: {
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        },
      },
    });
  };
  // buttonClick();

});

const answer = localStorage.getItem("token")
function App() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  const allowedPaths = [
    '/EmailAccountLoginBlock',
    '/',
    '/WellSpring',
    '/ContactUs',
  ];

  useEffect(() => {
    // console.log("came to plain useeffect");
    const permission = Notification.permission;
    // if (localStorageToken !== 'granted') {
    //   // if (window.location.pathname === "/EmailAccountLoginBlock") setOpen(true)
    //   if (localStorage.getItem("token")) {
    //     setOpen(true)
    //   }
    //   // localStorage.setItem("token", localStorageToken);
    // }
    generateToken(permission)
    // requestPermission()
  }, []);

  const requestPermission = async () => {
    if ("Notification" in window) {
      const permission = await Notification.requestPermission();
      console.log("Permission: ", permission);
      // if (permission === 'granted') {
      //   console.log('Notification permission granted.');
      //   setOpen(false)
      //   setShowPopup(false);
      // } else {
      //   setOpen(true)
      //   console.log('Notification permission denied.');
      // }

      if (permission === 'granted') {
        console.log('Notification permission granted.');
        setOpen(false);
        // setShowPopup(false);
        generateToken(permission)
        localStorage.setItem('notificationPermission', 'granted');
      } else {
        console.log('Notification permission denied.');
        // if (window.location.pathname === "/EmailAccountLoginBlock") setOpen(true);
        localStorage.setItem('notificationPermission', 'denied');
      }
      setOpen(false);
    } else {
      console.log("Notifications are not supported in this browser");
    }

  };

  const closePopup = () => {
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }



  useEffect(() => {
    const currentPath = window.location.pathname;
    if (!allowedPaths.includes(currentPath) && (!localStorage.getItem("token") || localStorage.getItem("token") === '') && localStorage.getItem("useEffect") !== 'no') {
      navigate('/EmailAccountLoginBlock');
    }

    if ("Notification" in window) {
      const notificationPermission = Notification.permission;
      if (notificationPermission !== 'granted' && notificationPermission !== 'denied') {
        // if (window.location.pathname === "/EmailAccountLoginBlock") setOpen(true);
        if (localStorage.getItem("token")) setOpen(true);
      }
    } else {
      console.log("Notifications are not supported in this browser");
    }
  }, [answer, navigate]);
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <button onClick={requestPermission} style={{ height: "30px" }}>Click me to enable notification</button> */}
        <ToastContainer />
        {window.location.pathname === '/EmailAccountLoginBlock' || window.location.pathname === '/' || window.location.pathname === '/WellSpring' || window.location.pathname === '/ContactUs' || (localStorage.getItem("token") && localStorage.getItem("token") !== '') ? (
          localStorage.setItem("useEffect", "yes"),
          <WebRoutesGenerator routeMap={routeMap} />
        ) : (
          <>
            <RouteGenerator routeMap={routeMap} />
          </>
        )}
        <AlertBlock />
        {/* {showPopup && ( */}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              borderRadius: '20px',
              padding: '20px',
              width: "390px"
            },
          }}
        >
          <Box style={Styles.dialogBox}>
            <Typography style={Styles.dialogTitle}>Enable Notifications</Typography>
            <Typography style={Styles.dialogContent}>Get timely reminders and updates to stay on track. Notifications help you stay informed</Typography>
            {/* <div style={Styles.buttonContainer}> */}
            <Grid container spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                <Button style={Styles.button} onClick={requestPermission}>
                  Allow
                </Button>

              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Button style={Styles.button1} onClick={closePopup}>
                Close
              </Button>
            </Grid>
            {/* </div> */}
          </Box>
        </Dialog>

        {/* )} */}
      </View>
    </BuilderProvider >
  );
}

const Styles = {
  dialogBox: {
    padding: '20px',
    borderRadius: '8px',
  },
  dialogTitle: {
    textAlign: 'center',
    margin: '0px',
    color: 'rgb(0, 167, 209)',
    fontFamily: "Josefin Sans",
    lineHeight: '40px',
    fontSize: '40px',
    letterSpacing: '-0.04em',
    fontWeight: 700
  },
  dialogContent: {
    margin: '20px 0px',
    letterSpacing: '0.00938em',
    color: 'rgb(37, 37, 37)',
    fontFamily: 'Chromatica-bold',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    padding: '8px 16px',
    textTransform: 'capitalize',
    color: "#fff",
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '4px',
    width: "80%",
    background: "linear-gradient(91.26deg, rgb(186, 202, 8) 1.42%, rgb(0, 167, 209) 99.81%)",
    borderRadius: '10px',
    height: '44px'
  },
  button1: {
    padding: '8px 16px',
    marginTop: '20px',
    textTransform: 'capitalize',
    color: "rgb(0, 167, 209)",
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '4px',
    width: "100%",
  },
  allowButton: {
    color: 'white',
    '&:hover': {
    },
  },
  closeButton: {
    color: 'white',
    '&:hover': {
    },
  },
}

export default App;
